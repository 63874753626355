<template>
  <div class="crud-entity">
    <LoadingSpinner :toggle="isLoading" />
    <form @submit.prevent="submit">
      <div class="sticky top-20 z-[9998] flex flex-row justify-between p-2 rounded-md shadow-md bg-slate-50">
        <div class="flex flex-row">
          <router-link v-if="enableBackTo" :to="`/${model}/`" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-slate-500 items-center mr-2"><f-icon icon="caret-left" class="mr-1" /> {{ $t('actions.backto') }}</router-link>          
          <div v-if="website_card_url.length > 0 && ['edit','show'].includes(mode) && ( (entity.card && entity.card.is_published) || entity.is_published)">
            <a v-for="(site, index) in website_card_url" :key="'site-'+index" class="bg-blue-300 text-blue-800 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md mr-2" :href="entity.card ? site.url+entity.card.code :  site.url+entity.code" target="_blank"><f-icon icon="arrow-up-right-from-square" class="mr-1 mt-1" /> {{ site.label }}</a>
          </div>
        </div>
        <div class="flex flex-row">
          <button v-if="mode !== 'show'" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="proceed">
            <span v-if="mode === 'new'">{{ actionButtonText || $t('actions.save') }}</span>
            <span v-else>{{ $t('actions.update') }}</span>
          </button>
        </div>
      </div>
      <div>
        <div v-if="!my_card && form && form.card && form.card.archive" class="bg-yellow-300 text-yellow-800 p-6 rounded-md mb-2">
            <span class="text-sm">{{ $t('components.crud.card_owned_by') }} <strong>{{ form.card.archive.name }}</strong></span><br/>
            <span>{{ $t('components.crud.card_owner_info') }}{{ getArchiveManagers() }}</span>
          </div>
          <div v-if="showParentInfo && parents && parents.length > 0" class="overflow-hidden rounded-t-md px-4 py-4 bg-slate-100">
            <div v-for="(parent, index) in parents" :key="'parent-'+index" class="">
              <div class="text-lg my-2">{{ $t('views.'+parent+'.title') }}</div>
              <div v-if="entity[parent] && entity[parent].length > 0">
                <div v-for="(parentEntity, index) in entity[parent]" :key="'parentEntity-'+index" class="flex flex-row items-center mb-1">
                  <div v-if="parentEntity.code" class="rounded px-2 py-1 text-white bg-primary text-xs mr-2">{{ parentEntity.code }}</div>
                  <div v-if="parentEntity.name" class="mr-2">{{ parentEntity.name }}</div>
                </div>
              </div>
              <div v-else>{{ $t('modals.error.no_parent', {name: $t('views.'+parent+'.title')}) }}</div>
            </div>
          </div>
      </div>
      <div class="">
          <div class="bg-white sm:px-6 sm:pb-6 px-4 pb-4">
            <div :class="`grid 
              grid-cols-${grid.sm} 
              md:grid-cols-${grid.md} 
              lg:grid-cols-${grid.lg} 
              xl:grid-cols-${grid.xl} 
              gap-6`" v-if="canLoad">
              <div :class="`col-span-1
                md:col-span-2 
                lg:col-span-3 
                xl:col-span-4`"></div>
              <div v-show="field.show" :class="field.type === 'separator' ? 
                `col-span-${span.sm} 
                md:col-span-${span.md} 
                lg:col-span-${span.lg} 
                xl:col-span-${span.xl}` : 
                `col-span-${field.span ? field.span <= span.sm ? field.span : span.sm : '1'} 
                md:col-span-${field.span ? field.span <= span.md ? field.span : span.md : '1'} 
                lg:col-span-${field.span ? field.span <= span.lg ? field.span : span.lg : '1'} 
                xl:col-span-${field.span ? field.span <= span.xl ? field.span : span.xl : '1'}`" v-for="(field, index) in formFields" :key="'edit-'+index">
                <label :for="field.field" class="text-sm font-medium text-slate-700 flex gap-1">
                  <div class="field-label">{{ field.name }}</div>
                  <div v-if="field.tip && field.show" class="has-tooltip text-xs text-slate-400">
                    <span class="tooltip rounded shadow-lg p-1 bg-slate-100 text-slate-500 -mt-8">{{ field.tip }}</span>
                    <f-icon icon="circle-question" />
                  </div>
                </label>
                <div>

                  <!-- Related model components (just most used) -->
                  <div v-if="field.related">

                    <!-- Sub-related model (e.g.: card->location) (just location used for now) -->
                    <div v-if="field.subrelated">
                      <!-- Input Type TEXT -->
                      <CrudInput
                        v-if="field.type === 'text' || field.type === 'password'"
                        v-model="form[field.related][field.subrelated][field.field]"
                        :type="field.type"
                        :field="field"
                        :disable="mode === 'show' || form.read_only"
                        classes="outline-none w-full h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" />

                      <!-- Input Type MAP -->
                      <div v-if="field.type === 'location'">
                        <div v-if="!form[field.related][field.subrelated][field.field]">
                          <div>{{ $t('components.map.want_add_location') }}</div>
                          <button @click="addLocation(field)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.add_location') }}</button>
                        </div>
                        <div v-else style="height: 400px; width: 100%" class="mb-5">
                          <div class="grid grid-cols-2 gap-6">
                            <input v-model="form[field.related][field.subrelated][field.field].coordinates[0]" class="outline-none h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" type="text" />
                            <input v-model="form[field.related][field.subrelated][field.field].coordinates[1]" class="outline-none h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" type="text" />
                          </div>
                          <div class="bg-slate-200 px-2 py-1 mb-1 text-xs text-slate-600">{{ $t('components.map.tip_double_click') }}</div>
                          <l-map
                            ref="map"
                            v-if="showMap"
                            :zoom="zoom"
                            :center="coords(form[field.related][field.subrelated][field.field].coordinates)"
                            :options="mapOptions"
                            style="height: 80%"
                            @update:center="centerUpdate"
                            @update:zoom="zoomUpdate"
                            @dblclick="updateCoords($event, field)"
                          >
                            <l-tile-layer :url="url" :attribution="attribution" />
                            <l-geosearch :options="geosearchOptions" />
                            <l-marker :lat-lng="coords(form[field.related][field.subrelated][field.field].coordinates)" :draggable="true" @move="updateCoords($event, field)">
                              <l-popup>
                                <div @click="innerClick">
                                  {{ $t('components.map.place_coordinates') + ' ' + coords(form[field.related][field.subrelated][field.field].coordinates) }}
                                  <p v-show="showParagraph">{{ $t('components.map.popup_default') }}</p>
                                </div>
                              </l-popup>
                            </l-marker>
                          </l-map>
                          <button @click="removeLocation(field)" class="bg-red-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.remove_location') }}</button>
                        </div>
                      </div>

                      <!-- Input Type GEOMETRY -->
                      <div v-if="field.type === 'geometry'">
                        <InputGeometry v-model="form[field.related][field.subrelated][field.field]" :field="field" :type="field.geometry" :mode="field.mode" :digitize-type="field.digitizeType" @updateGeometry="updateGeometry" :disable="mode === 'show' || form.read_only" />
                      </div>

                      <!-- Input Type SELECT (API) -->
                      <VSelect v-if="field.type === 'select'"
                        v-model="form[field.related][field.subrelated][field.field]"
                        :model="field.model"
                        :search-field="field.label"
                        :label="field.label"
                        :labels="field.labels"
                        :default-options="field.options"
                        :value-field="field.valueField || 'id'"
                        :placeholder="$t('global.select')"
                        class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        :disable="!!field.select || mode === 'show' || form.read_only"></VSelect>

                      <!-- Input Type RELATED -->
                      <div v-if="field.type === 'related'">
                        <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                          {{ $t('components.relatizer.save_before') }}
                        </div>
                        <Relatizer v-else
                          :disable="mode === 'show' || form.read_only"
                          v-model="form[field.related][field.model]"
                          :title="field.title"
                          :text="field.text"
                          :related-add-title="field.add"
                          :related-add-text="$t('components.relatizer.add')"
                          :related-update-text="$t('components.relatizer.update')"
                          :cancel-text="$t('actions.cancel')"
                          :model="field.related ? field.related : model"
                          :id="field.related ? form[field.related]['id'] : id"
                          :related-model="field.model"
                          :related-field="field.label"
                          :related-fields="field.fields"
                          :entities="form[field.related][field.model]"
                          />
                      </div>

                    </div><!-- / sub-related -->

                    <!-- Standard related model (e.g.: card) -->
                    <div v-else>
                  
                      <!-- Input Type TEXT -->
                      <CrudInput
                        v-if="field.type === 'text' || field.type === 'password'"
                        v-model="form[field.related][field.field]"
                        :type="field.type"
                        :field="field"
                        :disable="mode === 'show' || form.read_only"
                        classes="outline-none w-full h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" />

                      <!-- Input Type CHECKBOX -->
                      <CrudInput
                        v-if="field.type === 'checkbox'"
                        v-model="form[field.related][field.field]"
                        :field="field"
                        :type="field.type"
                        :disable="mode === 'show' || form.read_only"
                        classes="h-5 w-5 mt-2 ml-1 border-slate-50 text-teal-600" />

                      <!-- Input Type SELECT (API) -->
                      <VSelect v-if="field.type === 'select'"
                        v-model="form[field.related][field.field]"
                        :model="field.model"
                        :search-field="field.label"
                        :label="field.label"
                        :labels="field.labels"
                        :default-options="field.options"
                        :value-field="field.valueField || 'id'"
                        :placeholder="$t('global.select')"
                        class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        :disable="!!field.select || mode === 'show' || form.read_only"></VSelect>

                      <!-- Multiple associations handling (API) -->
                      <div v-if="field.type === 'multiple-checkbox'">
                        <div v-if="mode === 'edit' || mode === 'show'" class="flex flex-row flex-wrap pt-1">
                          <label v-for="(association, index) in field.associations" :key="`${field.field}-${index}`">
                            <button v-if="mode === 'edit'" :ref="`association-${field.field}-${index}`" class="text-white rounded-xl mr-1 mb-1 py-1 px-2 text-xs" :class="checkActiveAssociation(field.field, association, field.related) ? 'bg-green-600' : 'bg-slate-500'" @click="toggleAssociation(field.field, association, field.related)">
                              <span v-if="field.field === 'roles'">{{ $t('roles.'+association.name) }}</span>
                              <span v-else>{{ association.name }}</span>
                            </button>
                            <div v-else class="text-white rounded-xl mr-1 mb-1 py-1 px-2 text-xs" :class="checkActiveAssociation(field.field, association, field.related) ? 'bg-green-600' : 'bg-slate-500'">
                              <span v-if="field.field === 'roles'">{{ $t('roles.'+association.name) }}</span>
                              <span v-else>{{ association.name }}</span>
                            </div>
                          </label>
                        </div>
                        <div v-else class="px-3 py-2 bg-slate-200 rounded-md">{{ $t('tips.save_before') }}</div>
                      </div>

                      <!-- Input Type MULTI SELECT -->
                      <VSelectMulti v-if="field.type === 'multi-select'" 
                        v-model="form[field.related][field.field]" 
                        :model="field.model"
                        :search-field="field.label"
                        :label="field.label"
                        :labels="field.labels"
                        value-field="id"
                        :placeholder="$t('global.select')"
                        :disable="mode === 'show' || form.read_only"
                        :taggable="field.taggable || false"
                        :customLabel="field.customLabel"
                        class="mt-1 block w-full border-slate-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm" />

                      <!-- Input Type LOCATION MANAGER -->
                      <div v-if="field.type === 'location-manager'">
                        <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                          {{ $t('components.locations_manager.save_before') }}
                        </div>
                        <LocationManager v-else :field="field" :id="field.related ? form[field.related]['id'] : id" :digitize-type="field.digitizeType" @updateGeometry="updateLocations" :disable="mode === 'show' || form.read_only" :fields="field.fields" :pivots="field.pivots" :pivotTitle="field.pivotTitle" :wmsLayers="field.wmsLayers" />
                      </div>

                      <!-- Input Type LEMMA -->
                      <div v-if="field.type === 'lemma'">
                        <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                          {{ $t('components.lemmatizer.save_before') }}
                        </div>
                        <Lemmatizer v-else
                          v-model="form[field.related][field.field]"
                          :title="field.title"
                          :text="field.text"
                          :lemmaAssociateText="$t('components.lemmatizer.associate')"
                          :lemmaUpdateText="$t('components.lemmatizer.update')"
                          :addNewText="field.addNewText"
                          :cancelText="$t('actions.cancel')"
                          :model="field.related"
                          :relatedModel="field.relatedModel ? field.relatedModel : field.related"
                          :id="form[field.related].id"
                          :lemmaModel="field.model"
                          :lemmaField="field.label"
                          :lemmaPivots="field.pivots"
                          :entities="form[field.related][field.model]"
                          :labeled="field.labeled"
                          :taggable="field.taggable"
                          :disable="mode === 'show' || form.read_only" />
                      </div>

                      <!-- Input Type RELATIONS MANAGER -->
                      <div v-if="field.type === 'relations-manager'">
                        <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                          {{ $t('components.relations_manager.save_before') }}
                        </div>
                        <RelationsManager v-else :field="field" :id="field.related ? form[field.related]['id'] : id" :disable="mode === 'show' || form.read_only" :fields="field.fields" :pivots="field.pivots" :pivotTitle="field.pivotTitle" :relatedModel="field.relatedModel" :relatedExtendedModel="field.relatedExtendedModel" :relatedExtendedModelApi="field.relatedExtendedModelApi" :relatedModelApi="field.relatedModelApi" :relatedSearchField="field.relatedSearchField" :relatedLabel="field.relatedLabel" :ordering="field.ordering" :labels="field.labels" :taggable="field.taggable" :insert="field.insert" :template="field.template" />
                      </div>

                      <!-- Input Type RO-LIST (Read Only List) -->
                      <div v-if="field.type === 'ro-list' && mode !== 'new' && form[field.related][field.field] && form[field.related][field.field].length > 0" class="py-2 flex">
                        <div v-for="(element, index) in form[field.related][field.field]" :key="'ro-list-'+index">
                          <span class="rounded-md text-white bg-blue-500 px-2 py-1 text-xs mt-1 mr-1">{{ element[field.label] }}</span>
                        </div>
                      </div>

                      <!-- Input Type RO-TEXT (Read Only Related Text Field) -->
                      <div v-if="field.type === 'ro-text' && mode !== 'new'" class="py-2">
                        <span v-if="form[field.related] && form[field.related][field.field]" class="rounded-md text-white bg-blue-500 px-2 py-1 text-xs mt-1 mr-1">{{ form[field.related][field.field] }}</span>
                        <span v-else class="rounded-md text-white bg-slate-500 px-2 py-1 text-xs mt-1 mr-1">{{ $t('global.no_related') }}</span>
                      </div>

                    </div><!-- / standard-related -->

                  </div><!-- related model -->

                  <div v-else>

                    <!-- Fields Separator -->
                    <div v-if="field.type === 'separator' && canLoad">
                      <div class="text-lg py-3 border-b-2">{{ field.text }}</div>
                      <button v-if="field.hide && field.hiddenFields" @click="hideFields(field, index)" class="bg-slate-100 p-1 text-slate-500 rounded-b-md">{{ field.hiding ? $t('actions.show') : $t('actions.hide') }}</button>
                    </div>

                    <!-- Input Type TEXT -->
                    <CrudInput
                      v-if="field.type === 'text' || field.type === 'password'"
                      v-model="form[field.field]"
                      :field="field"
                      :type="field.type"
                      :disable="mode === 'show' || form.read_only"
                      classes="outline-none w-full h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" />

                    <!-- Input Type CHECKBOX -->
                    <CrudInput
                      v-if="field.type === 'checkbox'"
                      v-model="form[field.field]"
                      :field="field"
                      :type="field.type"
                      :disable="mode === 'show' || form.read_only"
                      classes="h-5 w-5 mt-2 ml-1 border-slate-50 text-teal-600" />

                    <!-- Input Type SELECT (API) -->
                    <VSelect v-if="field.type === 'select'"
                      v-model="form[field.field]"
                      :model="field.model"
                      :search-field="field.label"
                      :label="field.label"
                      :labels="field.labels"
                      :default-options="field.options"
                      :value-field="field.valueField || 'id'"
                      :placeholder="$t('global.select')"
                      :extra-params="field.extraParams"
                      :filter="field.watch"
                      class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      :watcher="form[field.watch]"
                      :disable="!!field.select || mode === 'show' || form.read_only"></VSelect>

                    <!-- Input Type MULTI SELECT -->
                    <VSelectMulti v-if="field.type === 'multi-select'" 
                      v-model="form[field.field]" 
                      :model="field.model"
                      :search-field="field.label"
                      :label="field.label"
                      :labels="field.labels"
                      value-field="id"
                      :placeholder="$t('global.select')"
                      :disable="mode === 'show' || form.read_only"
                      :taggable="field.taggable || false"
                      :customLabel="field.customLabel"
                      class="mt-1 block w-full border-slate-300 bg-white rounded-md focus:outline-none" />

                    <!-- Multiple associations handling (API) -->
                    <div v-if="field.type === 'multiple-checkbox'">
                      <div v-if="mode === 'edit' || mode === 'show'" class="flex flex-row flex-wrap">
                        <label v-for="(association, index) in field.associations" :key="`${field.field}-${index}`">
                          <button :ref="`association-${field.field}-${index}`" class="text-white rounded-xl m-1 py-1 px-2 text-xs" :class="checkActiveAssociation(field.field, association) ? 'bg-green-600' : 'bg-slate-500'" @click="toggleAssociation(field.field, association)" :disable="!!field.select || mode === 'show' || form.read_only">
                            <span v-if="field.field === 'roles'">{{ $t('roles.'+association.name) }}</span>
                            <span v-else>{{ association.name }}</span>
                          </button>
                        </label>
                      </div>
                      <div v-else class="px-3 py-2 bg-slate-200 rounded-md">{{ $t('tips.save_before') }}</div>
                    </div>

                    <!-- Input Type STATIC SELECT -->
                    <select v-if="field.type === 'static-select'" v-model="form[field.field]" class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" :disabled="mode === 'show' || form.read_only">
                      <option disabled value="">{{ $t('global.select') }}</option>
                      <option value="">{{ $t('global.select_no_value') }}</option>
                      <option v-for="(option, index) in field.options" :key="`static-option-${index}`" :value="option.value">
                        {{ option.name }}
                      </option>
                    </select>

                    <!-- Input Type MAP -->
                    <div v-if="field.type === 'location'">
                      <div v-if="!form[field.field]">
                        <div>{{ $t('components.map.want_add_location') }}</div>
                        <button @click="addLocation(field)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.add_location') }}</button>
                      </div>
                      <div v-else style="height: 400px; width: 100%" class="mb-5">
                        <div class="grid grid-cols-2 gap-6">
                          <input v-model="form[field.field].coordinates[0]" class="outline-none h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" type="text" />
                          <input v-model="form[field.field].coordinates[1]" class="outline-none h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" type="text" />
                        </div>
                        <div class="bg-slate-200 px-2 py-1 mb-1 text-xs text-slate-600">{{ $t('components.map.tip_double_click') }}</div>
                        <l-map
                          ref="map"
                          v-if="showMap"
                          :zoom="zoom"
                          :center="coords(form[field.field].coordinates)"
                          :options="mapOptions"
                          style="height: 80%"
                          @update:center="centerUpdate"
                          @update:zoom="zoomUpdate"
                          @dblclick="updateCoords($event, field)"
                        >
                          <l-tile-layer :url="url" :attribution="attribution" />
                          <l-geosearch :options="geosearchOptions" />
                          <l-marker :lat-lng="coords(form[field.field].coordinates)" :draggable="true" @move="updateCoords($event, field)">
                            <l-popup>
                              <div @click="innerClick">
                                {{ $t('components.map.place_coordinates') + ' ' + coords(form[field.field].coordinates) }}
                                <p v-show="showParagraph">{{ $t('components.map.popup_default') }}</p>
                              </div>
                            </l-popup>
                          </l-marker>
                        </l-map>
                        <button v-if="mode !== 'show' || !form.read_only" @click="removeLocation(field)" class="bg-red-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.remove_location') }}</button>
                      </div>
                    </div>

                    <!-- Input Type TEXTEDITOR -->
                    <TextEditor
                      v-if="field.type === 'text-editor'"
                      v-model="form[field.field]"
                      :disable="mode === 'show' || form.read_only" />

                    <!-- Input Type LEMMA -->
                    <div v-if="field.type === 'lemma'">
                      <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                        {{ $t('components.lemmatizer.save_before') }}
                      </div>
                      <Lemmatizer v-else
                        v-model="form[field.field]"
                        :title="field.title"
                        :text="field.text"
                        :lemmaAssociateText="$t('components.lemmatizer.associate')"
                        :lemmaUpdateText="$t('components.lemmatizer.update')"
                        :addNewText="field.addNewText"
                        :cancelText="$t('actions.cancel')"
                        :model="model"
                        :id="id"
                        :lemmaModel="field.model"
                        :lemmaField="field.label"
                        :lemmaPivots="field.pivots"
                        :entities="form[field.model]"
                        :labeled="field.labeled"
                        :taggable="field.taggable"
                        :disable="mode === 'show' || form.read_only" />
                    </div>

                    <!-- Input Type COLORPICKER -->
                    <div v-if="field.type === 'color'">
                      <ColorPicker v-model="form[field.field]" />
                    </div>

                    <!-- Input Type GEOMETRY -->
                    <div v-if="field.type === 'geometry'">
                      <InputGeometry v-model="form[field.field]" :field="field" :type="field.geometry" :mode="field.mode" :digitize-type="field.digitizeType"  @updateGeometry="updateGeometry" :disable="mode === 'show' || form.read_only" />
                    </div>

                    <!-- Input Type RELATED -->
                    <div v-if="field.type === 'related'">
                      <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                        {{ $t('components.relatizer.save_before') }}
                      </div>
                      <Relatizer v-else
                        :disable="mode === 'show' || form.read_only"
                        v-model="form[field.field]"
                        :title="field.title"
                        :text="field.text"
                        :related-add-title="field.add"
                        :related-add-text="$t('components.relatizer.add')"
                        :related-update-text="$t('components.relatizer.update')"
                        :cancel-text="$t('actions.cancel')"
                        :model="model"
                        :id="id"
                        :related-model="field.model"
                        :related-field="field.label"
                        :related-fields="field.fields"
                        :entities="form[field.model]" />
                    </div>

                    <!-- Input Type RO-LIST (Read Only List) -->
                    <div v-if="field.type === 'ro-list' && mode !== 'new' && form[field.field] && form[field.field].length > 0" class="py-2 flex">
                      <div v-for="(element, index) in form[field.field]" :key="'ro-list-'+index">
                        <span class="rounded-md text-white bg-blue-500 px-2 py-1 text-xs mt-1 mr-1">{{ element[field.label] }}</span>
                      </div>
                    </div>

                    <!-- Input Type RO-LIST (Read Only List) -->
                    <div v-if="field.type === 'count' && mode !== 'new'" class="py-2">
                      <span class="rounded-md text-white bg-blue-500 px-2 py-1 text-xs">{{ form[field.field].length }}</span>
                    </div>

                    <div v-if="field.type === 'datetime'" class="py-2">
                      <span v-html="datetime(form[field.field])"></span>
                    </div>

                    <!-- Input Type DATEPICKER -->
                    <div v-if="field.type === 'date'" class="outline-none w-full h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline">
                      <vue-date-picker v-model="form[field.field]" value-type="YYYY-MM-DD" format="YYYY-MM-DD" :disabled="mode === 'show' || form.read_only" input-class="px-2 py-1 outline-none" popup-class="" />
                    </div>

                    <!-- Input Type LOCATION MANAGER -->
                    <div v-if="field.type === 'location-manager'">
                      <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                          {{ $t('components.locations_manager.save_before') }}
                        </div>
                      <LocationManager v-else :field="field" :id="id" :digitize-type="field.digitizeType" @updateGeometry="updateLocations" :disable="mode === 'show' || form.read_only" :fields="field.fields" :pivots="field.pivots" :pivotTitle="field.pivotTitle" :model="field.model || model" :wmsLayers="field.wmsLayers" />
                    </div>

                    <!-- Input Type RELATIONS MANAGER -->
                    <div v-if="field.type === 'relations-manager'">
                        <div v-if="mode === 'show' || mode === 'new' || form.read_only" class="mb-2 bg-slate-300 p-2 rounded-md">
                          {{ $t('components.relations_manager.save_before') }}
                        </div>
                        <RelationsManager v-else :field="field" :id="id" :disable="mode === 'show' || form.read_only" :fields="field.fields" :pivots="field.pivots" :pivotTitle="field.pivotTitle" :model="model" :relatedModel="field.relatedModel" :relatedExtendedModel="field.relatedExtendedModel" :relatedExtendedModelApi="field.relatedExtendedModelApi" :relatedModelApi="field.relatedModelApi" :relatedSearchField="field.relatedSearchField" :relatedLabel="field.relatedLabel" :ordering="field.ordering" :editing="field.editing" :add-new-text="field.addNewText" :tip="field.handleTip" :labels="field.labels" :taggable="field.taggable" :insert="field.insert" :template="field.template" :extra-params="{filter: field.filter, extraParams: extraParams}" />
                    </div>

                    <div v-if="field.type === 'image'">
                      <img v-if="preview(entity)" :src="preview(entity)" alt="file preview" class="rounded-sm">
                      <span v-else class="rounded-md text-white bg-slate-500 px-2 py-1 text-xs mt-1 mr-1">{{ $t('global.no_preview') }}</span>
                    </div>

                  </div><!-- / simple-model -->

                  <!-- Input Type REPEATING-TEXT --> <!-- TODO: capire se deve salire su dentro il simple-model! -->
                  <RepeatingText
                    v-if="field.type === 'repeating-text'"
                    v-model="form[field.field]"
                    :field="field"
                    :disable="mode === 'show' || form.read_only"
                    classes="outline-none w-full h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" />
                  
                  <span v-if="showError && Array.isArray(errors) && errors.length > 0" class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">{{ errors[field.field] }}</span>

                </div>
              </div>
              
            </div>
          </div>
        </div>
    </form>
    <v-idle
      v-if="checkDraft"
      class="hidden"
      @idle="onidle"
      @remind="onremind"
      :loop="true"
      :reminders="[120]"
      :wait="5"
      :duration="90" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { latLng } from "leaflet";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
// import "leaflet-geosearch/dist/geosearch.css";
import api from '@/services/api'
import appConfig from '@/config'

import CrudInput from '@/components/CrudInput'
import TextEditor from '@/components/TextEditor'
import Lemmatizer from '@/components/Lemmatizer'
import ColorPicker from '@/components/ColorPicker'
import InputGeometry from '@/components/InputGeometry'
import Relatizer from '@/components/Relatizer'
import RepeatingText from '@/components/RepeatingText'
import LocationManager from '@/components/LocationManager'
import LoadingSpinner from '@/components/LoadingSpinner'
import RelationsManager from '@/components/RelationsManager'

export default {
  name: 'CrudEntity',
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user'
    }),
  },
  components: {
    CrudInput, TextEditor, Lemmatizer, ColorPicker, InputGeometry, Relatizer, RepeatingText, LocationManager, LoadingSpinner, RelationsManager
  },
  props: {
    model: {
      type: String,
      value: null
    },
    id: {
      type: [String,Number],
      value: null
    },
    fields: {
      type: Array,
      value: null
    },
    mode: {
      type: String,
      default: 'edit'
    },
    entity: {
      type: Object,
      required: true
    },
    checkOwner: {
      type: Boolean,
      default: false
    },
    isExtendedCard: {
      type: Boolean,
      default: false
    },
    extends: {
      type: String,
      default: 'card'
    },
    hasLocation: {
      type: Boolean,
      default: false
    },
    checkDraft: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Object,
      default() {
        return {
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
        }
      }
    },
    span: {
      type: Object,
      default() {
        return {
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
        }
      }
    },
    showParentInfo: {
      type: Boolean,
      default: false
    },
    parents: {
      type: Array,
      default: () => [] 
    },
    actionButtonText: {
      type: String,
      default: null
    },
    enableBackTo: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Boolean,
      default: true
    }
  },
  data: function(){
    return {
      canLoad: false,
      form: {
        read_only: false
      },
      showError: false,
      errors: [],
      canSubmit: false,
      zoom: 15,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      isLoading: false,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false
      },
      tileOptions: {
        maxZoom: 18, // maxZoom: testCRS.options.resolutions.length,
        maxNativeZoom: 18,
        minZoom: 0,
        continuousWorld: true,
        worldCopyJump: false
      },
      showMap: true,
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        searchLabel: 'Search...',
        autoClose: true
      },
      my_card: false,
      website_card_url: [],
      storeOnIdle: false,
      formFields: [],
      separators: [],
      extraParams: {}
    }
  },
  created(){
    this.url = process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    this.attribution = process.env.VUE_APP_MAP_ATTRIBUTION || '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    this.tileOptions.minZoom = process.env.VUE_APP_MAP_MIN_ZOOM || 1
    this.tileOptions.maxZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
    this.tileOptions.maxNativeZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
  },
  async mounted(){
    const vm = this
    vm.formFields = vm.fields
    vm.separators = []
    if(['edit','show'].includes(vm.mode) && vm.id){
      // Setup form fields
      for(var i = 0; i < vm.formFields.length; i++) {
        vm.$set(vm.formFields[i], 'show', true)
        // check if is separator (to handle hiding fields)
        if(vm.formFields[i].type === 'separator') {
          vm.separators.push({...vm.formFields[i], ...{index: i}})
        }
        // check if form contains 'multiple-checkbox'
        if(vm.formFields[i].type === 'multiple-checkbox') {
          // load associations to populate checkboxes
          await api.fetch(vm.formFields[i].field, null, {'params': {'per_page': '99999'}}).then((response) => {
            vm.$set(vm.formFields[i], 'associations', response.data)
          })
        }
      }
      vm.form = vm.entity // await api.fetch(this.model, this.id)
      
      if(vm.checkRelated(vm.entity.projects)){
        vm.entity.projects.forEach(project => {
          if(vm.checkRelated(project.tenants)){
            vm.$set(vm.extraParams, 'tenants', vm.pluck(project.tenants, 'code'))
          }
        })
      }
      // check tenants
      if(vm.checkRelated(vm.entity.tenants)){
        vm.$set(vm.extraParams, 'tenants', vm.pluck(vm.entity.tenants, 'code'))
      }

      // if location isn't created: // Note: Removed because added button "add location"
      // if(!this.form.location) {
      //   this.$set(this.form, 'location', {coordinates: this.defaultCoords(), type:'Point'})
      // }
      // check owner
      if(vm.checkOwner) vm.user.archives.forEach(function(archive){
        if(archive.id === vm.entity.card.archive_id) {
          vm.my_card = vm.entity.card && vm.entity.card.archive_id && vm.entity.card.archive_id === archive.id
        }
        if(vm.user.id === vm.entity.card.creator_id) vm.my_card = true
      })
      vm.canLoad = true
    }else{
      // default value field assignations
      for(var j = 0; j < vm.formFields.length; j++) {
        vm.$set(vm.formFields[j], 'show', true)
        // check if is separator (to handle hiding fields)
        if(vm.formFields[j].type === 'separator') {
          vm.separators.push({...vm.formFields[j], ...{index: j}})
        }
        // check for pre-selected select model id
        if(vm.formFields[j].type === 'select' && vm.formFields[j].select){
          vm.form[vm.formFields[j].field] = vm.formFields[j].select
        }else if(vm.formFields[j].type === 'location'){
          // setup default project coordinates // Note: Removed because added button "add location"
          // this.$set(this.form, [this.formFields[j].field], {coordinates: this.defaultCoords(), type:'Point'})
        }else if(vm.formFields[j].related){
          // setup default related property as empty object
          vm.$set(vm.form, vm.formFields[j].related, {})
        }
      }
      // setup empty fields for extended card (w/ location)
      if(vm.isExtendedCard){
        vm.$set(vm.form, vm.extends, {})
        if(vm.hasLocation) vm.$set(vm.form, vm.extends, {location: {}})
      }
      vm.form.creator_id = vm.user.id // setup creator as person which store card first time
      vm.canLoad = true
    }
    vm.$nextTick(()=>{
      
      let url_env = process.env.NODE_ENV === 'production' ? 'url_production' : 'url_development'

      // build website preview links
      switch(vm.entity.slug){
        case 'card_containers':
          if(vm.entity.card_container_type && vm.entity.projects && vm.entity.projects.length > 0) {
            vm.entity.projects.forEach(project => {
              if(project.tenants && project.tenants.length > 0) project.tenants.forEach(tenant => {
                vm.website_card_url.push({
                  url: tenant[url_env] + '/' + appConfig.base_url[vm.entity.slug] + vm.entity.card_container_type.code + '/',
                  label: tenant.name
                })
              })
            })
          }
          break
        case 'persons':
          if(vm.entity.registry && vm.entity.registry.tenants && vm.entity.registry.tenants.length > 0) vm.entity.registry.tenants.forEach(tenant => {
            vm.website_card_url.push({
              url: tenant[url_env] + '/' + appConfig.base_url[vm.entity.slug],
              label: tenant.name
            })
          })
          break
        default:
        if(vm.entity.card && vm.entity.card.archive.tenants && vm.entity.card.archive.tenants.length > 0) vm.entity.card.archive.tenants.forEach(tenant => {
            vm.website_card_url.push({
              url: tenant[url_env] + '/' + appConfig.base_url[vm.entity.card.slug],
              label: tenant.name
            })
          })
          break;  
      }

      // check website url by env // TODO da rivedere in alcuni casi
      // if(vm.entity.slug === 'persons'){
      //   if(vm.entity.registry && vm.entity.registry.tenants && vm.entity.registry.tenants.length > 0){
      //     vm.website_card_url = vm.entity.registry.tenants[0][url_env] + '/' + appConfig.base_url[vm.entity.slug]
      //   }
      // }else if(!!vm.entity.card && !!vm.entity.card.archive && !!vm.entity.card.archive.tenants && vm.entity.card.archive.tenants.length > 0){
      //   vm.website_card_url = process.env.NODE_ENV === 'production' ? vm.entity.card.archive.tenants[0].url_production : vm.entity.card.archive.tenants[0].url_staging + '/' + appConfig.base_url[vm.entity.card.slug]
      // }

      // if(vm.canLoad && vm.$refs.map) vm.$refs.map.mapObject.on("geosearch/showlocation", vm.onSearch);

      vm.separators.forEach((separator) => {
        if(separator.hide) {  
          separator.hiding = false
          vm.hideFields(separator, separator.index)
        }
      })
      
    })
  },
  methods: {
    proceed() {
      this.canSubmit = true
    },
    async submit() {
      const vm = this
      vm.isLoading = true
      if(vm.canSubmit) try {
        vm.errors = []
        vm.showError = false
        if(vm.mode === 'edit') {
          await api.update(vm.model, vm.id, vm.form).then((response) => {
            if(response.success){
              // successfully updated
              vm.showError = false
              vm.form = response.data
              vm.$toasted.success(response.message || this.$i18n.t('modals.update.confirmed')) //, { icon : { name: 'check' } })
            }else{
              // mapping errors
              for(let error in response.errors){
                vm.errors[error] = response.errors[error]
                vm.$toasted.error(response.errors[error])
              }
              vm.showError = true
            }
            vm.isLoading = false
          })
        }else{
          await api.create(vm.model, { ...vm.form}).then((response) => {
            // se c'è un errore 
            if(response.success){
              // se ha salvato redirect sulla pagina di modifica
              vm.showError = false
              // se è stato effettuato un salvataggio in bozza automatica rimanda direttamente alla scheda
              if(vm.storeOnIdle) {
                vm.$router.push({path: `/${vm.model}/edit/${response.data.id}`})
                vm.$router.go()
              }else{
                if(vm.redirect) {
                  vm.$router.push({path: `/${vm.model}`})
                }
                vm.$emit('save', 'saved')
              }
            }else{
              // mapping errors
              for(let error in response.errors){
                vm.errors[error] = response.errors[error]
                vm.$toasted.error(response.errors[error])
              }
              // TODO populate fields with error as sublabel
              vm.showError = true
            }
            vm.isLoading = false
          })
        }
      } catch (error){
        vm.showError = true
        vm.errors = error
        vm.$toasted.show(vm.errors)
        vm.isLoading = false
      }
      vm.isLoading = false
      vm.canSubmit = false
    },
    checkActiveAssociation(field, association, related = null){
      let found = false
      if(related){ // for related model parent
        if(this.form[related][field]) for(var ir = 0; ir < this.form[related][field].length; ir++) {
          if (this.form[related][field][ir].id === association.id) {
            found = true;
            break;
          }
        }
      }else{
        if(this.form[field]) for(var i = 0; i < this.form[field].length; i++) {
          if (this.form[field][i].id === association.id) {
            found = true;
            break;
          }
        }
      }
      return found
    },
    toggleAssociation(field, association, related = null){
      let found = false
      if(related){ // for related model parent
        if(this.form[related][field]) for(var ir = 0; ir < this.form[related][field].length; ir++) {
          if (this.form[related][field][ir].id === association.id) {
            found = true;
            break;
          }
        }
        if(found) {
          this.form[related][field].splice(this.form[related][field].indexOf(association), 1);
        }else{
          this.form[related][field].push(association)
        }
      }else{
        // console.log('[check assiciations]', association, this.form[field], this.form[field].indexOf(association))
        if(this.form[field]) for(var i = 0; i < this.form[field].length; i++) {
          if (this.form[field][i].id === association.id) {
            found = true;
            break;
          }
        }
        if(found) {
          this.form[field].splice(this.form[field].indexOf(association), 1);
        }else{
          this.form[field].push(association)
        }
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    coords(coordinates){
      return latLng(coordinates[1], coordinates[0])
    },
    updateCoords(event, field){
      if(field && field.related){
        if(field && field.related && field.subrelated){ 
          this.$set(this.form[field.related][field.subrelated][field.field], 'coordinates', [event.latlng.lng, event.latlng.lat])
        }
        else{
          this.$set(this.form[field.related][field.field], 'coordinates', [event.latlng.lng, event.latlng.lat])
        }
      }else{
        this.$set(this.form[field.field], 'coordinates', [event.latlng.lng, event.latlng.lat])
      }
      //var location = this.formFields.filter(field => { return field.type === 'location' })
      // if(location) {
      //   this.form[location[0].field].coordinates = [event.latlng.lng, event.latlng.lat]
      // }
    },
    defaultCoords(){
      return (process.env.VUE_APP_BASE_LAT && process.env.VUE_APP_BASE_LNG) 
        ? [parseFloat(process.env.VUE_APP_BASE_LAT), parseFloat(process.env.VUE_APP_BASE_LNG)]
        : [parseFloat(12.1234), parseFloat(44.1234)]
    },
    onSearch(value){
      // geosearch/showlocation
      console.log('onSearch', value)
    },
    updateGeometry(data){
      const vm = this

      var field_ref = vm.form
      if(data.field.related){
        if(data.field.subrelated){
          field_ref = vm.form[data.field.related][data.field.subrelated]
        }else{
          field_ref = vm.form[data.field.related]
        }
      }
      if(data){
        // to setup empty geometry
        if(data.action === 'create'){
          let coords_data = null
          switch(data.type){
            // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
            case 'polygon':
            case 'polygonz':
            case 'linestringz':
              coords_data = {coordinates: [data.coordinates], type: data.type}
              break
            case 'location':
            case 'point':
            case 'pointz':
            case 'multipointz':
              coords_data = {coordinates: data.coordinates, type: data.type}
              break
          }
          vm.$set(field_ref, data.geometry, coords_data)
        }
        // to remove a geometry
        if(data.action === 'remove'){
          //console.log('[remove geometry]', data.index, field_ref[data.geometry])
          if(data.index) { // to remove just one PointZ from MultiPointZ
            switch(data.type){
              // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
              case 'polygon':
              case 'polygonz':
                vm.$delete(field_ref[data.geometry]['coordinates'][0], data.index)
                break
              case 'multipointz':
              case 'linestringz':
              default:
                vm.$delete(field_ref[data.geometry]['coordinates'], data.index)
                break
            }
          }else{ // for others geometries
            vm.$set(field_ref, data.geometry, null)
          }
        }
        // to add a point to a geometry (e.g.: MultiPointZ)
        if(data.action === 'add'){
          //console.log('[add geometry]', data.index, field_ref[data.geometry]['coordinates'])
          switch(data.type){
            // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
            case 'polygon':
            case 'polygonz':
            case 'multipointz':
              vm.$set(field_ref[data.geometry]['coordinates'][0], data.index, data.coordinates)
              break
            case 'linestringz':
            default:
              vm.$set(field_ref[data.geometry]['coordinates'], data.index, data.coordinates)
              break;
          }
        }
      }
    },
    removeLocation(field){
      if(field.related){
        if(field.subrelated) { this.$set(this.form[field.related][field.subrelated], field.field, null) }
        else { this.$set(this.form[field.related], field.field, null) }
      }else{
        this.$set(this.form, field.field, null)
      }
    },
    addLocation(field){
      if(field.related){
        if(field.subrelated) { this.$set(this.form[field.related][field.subrelated], field.field, {coordinates: this.defaultCoords(), type:'Point'}) }
        else { this.$set(this.form[field.related], field.field, {coordinates: this.defaultCoords(), type:'Point'}) }
      }else{
        this.$set(this.form, field.field, {coordinates: this.defaultCoords(), type:'Point'})
      }
      // this.$nextTick(()=>{
      //     this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
      // })
    },
    getArchiveManagers(){
      if(this.form.card.archive.users && this.form.card.archive.users.length > 0){
        let managers = this.form.card.archive.users.map(function(user) { return user.email }).join(', ')
        return ': ' + (managers || '')
      } 
      return ''
    },
    onidle() {
      if(this.mode === 'new' && this.form.card && this.form.card.code && this.form.card.code !== null) {
        this.canSubmit = true
        this.storeOnIdle = true // need to redirect in edit page automatically
        this.submit()
      }
    },
    onremind() { // (time)
      // alert seconds remaining to 00:00
      this.$toasted.info(this.$i18n.t('tips.remember_update'))
      if(this.mode === 'new') this.$toasted.info(this.$i18n.t('tips.remember_save'))    
    },
    datetime(datetime){
      if(!datetime) return 'Ancora non verificato'
      let format = new Date(datetime)
      return format.getDate()+'/' + (format.getMonth()+1) + '/'+format.getFullYear()+' - '+ ((format.getHours() < 10 ? '0' : '') + format.getHours()) +':'+ ((format.getMinutes() < 10 ? '0' : '') + format.getMinutes())
    },
    updateLocations(data){
      console.log('[updateLocations]', data)
    },
    hideFields(separator, index){
      const vm = this
      vm.formFields.forEach((field, fieldIndex) => {
        if(separator.hiddenFields.includes(field.field)) {
          vm.$set(vm.formFields[fieldIndex], 'show', !vm.formFields[fieldIndex].show)
        }
        if(index === fieldIndex) vm.$set(vm.formFields[fieldIndex], 'hiding', !separator.hiding)
      })
    },
    checkRelated(related){
      return related && related.length > 0
    },
    pluck(arr, key) {
      return arr.map(i => i[key])
    },
    preview(file){
      if(file.is_image) return file.web[0].file
      return false
    },
    filterForm(fields){
      let filteredForm = []
      fields.forEach(field => {
        console.log('[filtering form]', field, this.form[field.field])
        if(this.form[field.field]) filteredForm[field.field] = this.form[field.field]
      })
      console.log(filteredForm)
      return filteredForm
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.leaflet-control-container {
  display: flex;
  .leaflet-top.leaflet-left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    .geosearch {
      display: flex;
      background: #fff;
      form {
        display: flex;
        flex-direction: column;
        .glass {
          height: 30px;
          padding: 5px 10px;
        }
        .reset {
          position: absolute;
          right: 0px;
        }
        .results {
          padding: 10px
        }
      }
    }
  }
}
// .leaflet-control-geosearch {
//   position: absolute !important;
//   top: 0 !important;
//   right: 0px !important;
//   width: 25vw !important;
// }
</style>
