<template>
  <div></div>
</template>
<script>
import api from '@/services/api'
import { mapGetters } from 'vuex'

export default {
  name: 'CrudLoad',
  data () {
    return {
      id: null,
      entity: {},
      select_user: null,
      canLoad: false,
      crud_mode: 'edit',
      lists: []
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user'
    }),
  },
  methods: {
    async load(mode = 'edit', id = null) {
      const vm = this
      if(['edit','show'].includes(mode)) vm.id = id
    
      if(vm.isAuthenticated) await api.fetch('lists').then((data) => {
        vm.lists = data.data
      })

      if(vm.id){
        await api.fetch(vm.model, vm.id).then((entity) => {
          vm.entity = entity
          vm.canLoad = true
          vm.crud_mode = mode

          // handle archive association beetween card and archive owner, if relationship exist
          let archive_id = vm.entity.card && vm.entity.card.archive_id ? vm.entity.card.archive_id : vm.entity.archive_id ? vm.entity.archive_id : null
          if(archive_id) {
            let archives = vm.user.archives.filter(function(archive){
              return archive_id === archive.id
            })
            vm.crud_mode = archives.length === 0 ? 'show' : mode
          }
        })
      }else{
        vm.crud_mode = 'new'
        vm.canLoad = true
      } 

      // force user pre-select for card_manager user
      if(vm.user.user_roles.includes('card_manager') && vm.user.user_roles.length === 1){
        vm.select_user = vm.user.id
      }
    },
  }
}
</script>
